var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"1150px","centered":"","mask-closable":false,"loading":_vm.submitting,"title":"编辑客户套餐"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-form',{staticClass:"iotplt-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('span',{staticClass:"item-title"},[_vm._v("基本信息")]),_c('a-form-item',{attrs:{"label":"客户套餐名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入客户套餐名称' },
                    { max: 100, message: '最多100个字符' } ]
                }]),expression:"['name', {\n                  normalize: this.$lodash.trim,\n                  rules: [\n                    { required: true, message: '请输入客户套餐名称' },\n                    { max: 100, message: '最多100个字符' },\n                  ]\n                }]"}]})],1),_c('a-form-item',{staticClass:"iotplt-display-item",attrs:{"label":"套餐名称"}},[_vm._v(" "+_vm._s(_vm.data.top_agent_product_name)+" ")]),_c('a-form-item',{staticClass:"iotplt-display-item",attrs:{"label":"运营商种类"}},[_vm._v(" "+_vm._s(_vm.data.carrier_type)+" ")]),_c('a-form-item',{staticClass:"iotplt-display-item",attrs:{"label":"用户业务模式"}},[_vm._v(" "+_vm._s(_vm.data.user_business_type)+" ")]),_c('a-form-item',{staticClass:"iotplt-display-item",attrs:{"label":"套餐周期"}},[_vm._v(" "+_vm._s(_vm.data.service_cycle)+" ")]),_c('a-form-item',{staticClass:"iotplt-display-item",attrs:{"label":"套餐容量"}},[_vm._v(" "+_vm._s(_vm.data.package_capacity)+" ")]),_c('a-form-item',{staticClass:"iotplt-display-item",attrs:{"label":"语音"}},[_vm._v(" "+_vm._s(_vm.data.voice_capacity)+" ")]),_c('a-form-item',{attrs:{"label":"套餐描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description', {
                  rules: [
                    { max: 200, message: '最多200个字符' } ]
                }]),expression:"['description', {\n                  rules: [\n                    { max: 200, message: '最多200个字符' },\n                  ]\n                }]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('span',{staticClass:"item-title"},[_vm._v("价格设置")]),_c('a-row',{staticStyle:{"padding-left":"2px","margin-top":"10px","margin-bottom":"10px"},attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_vm._v("周期数")]),_c('a-col',{attrs:{"span":8}},[_vm._v("套餐价格(元)")]),_c('a-col',{attrs:{"span":8}},[_vm._v("客户价格(元)*")])],1),_vm._l((_vm.agentsProductFavorablePrices),function(item,index){return _c('a-row',{key:item.id,attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"iotplt-display-item"},[_vm._v(" "+_vm._s(item.cycles)+" ")])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"iotplt-display-item"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.top_agent_distributor_price))+" ")])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"iotplt-display-item"},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("agents_product_favorable_prices[" + index + "].distributor_price"), {
                      initialValue: item.distributor_price >= 0 ? item.distributor_price : undefined,
                      rules: [
                        { required: true, message: '请输入客户价格' }
                      ]
                    }]),expression:"[`agents_product_favorable_prices[${index}].distributor_price`, {\n                      initialValue: item.distributor_price >= 0 ? item.distributor_price : undefined,\n                      rules: [\n                        { required: true, message: '请输入客户价格' }\n                      ]\n                    }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2,"disabled":_vm.isMerchantSales}})],1)],1)],1)}),(_vm.isShowExcessRateItem)?_c('a-row',{staticStyle:{"margin-top":"10px"},attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_vm._v(" 超额计费单位 ")]),_c('a-col',{attrs:{"span":8}},[_vm._v(" 价格(元) ")]),_c('a-col',{attrs:{"span":8}},[_vm._v(" 客户价格(元)* ")])],1):_vm._e(),(_vm.isShowExcessRateItem)?_c('a-row',{staticStyle:{"margin-top":"10px"},attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"iotplt-display-item"},[_vm._v(" "+_vm._s(_vm.data.excess_rate_quantity)+" ")])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"iotplt-display-item"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.data.top_agent_excess_distributor_price))+" ")])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['excess_distributor_price', {
                      initialValue: _vm.data.excess_distributor_price < 0 ? undefined : _vm.data.excess_distributor_price,
                      rules: [
                        { required: true, message: '请输入客户价格' }
                      ]
                    }]),expression:"['excess_distributor_price', {\n                      initialValue: data.excess_distributor_price < 0 ? undefined : data.excess_distributor_price,\n                      rules: [\n                        { required: true, message: '请输入客户价格' }\n                      ]\n                    }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2,"disabled":_vm.isMerchantSales}})],1)],1)],1):_vm._e(),_c('span',{staticClass:"item-title"},[_vm._v("卡片设置")]),_c('a-row',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_vm._v(" 卡片材质 ")]),_c('a-col',{attrs:{"span":8}},[_vm._v(" 卡板价格(元) ")]),_c('a-col',{attrs:{"span":8}},[_vm._v(" 客户价格(元)* ")])],1),_vm._l((_vm.agentsProductsCardTypes),function(cardType,index){return _c('a-row',{key:cardType.id,attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"iotplt-display-item"},[_vm._v(" "+_vm._s(cardType.card_type_name)+" ")])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"iotplt-display-item"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(cardType.top_agent_price))+" ")])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("agents_products_card_types[" + index + "].price"), {
                      initialValue: cardType.price < 0 ? undefined : cardType.price,
                      rules: [
                        { required: true, message: '请输入客户价格' }
                      ]
                    }]),expression:"[`agents_products_card_types[${index}].price`, {\n                      initialValue: cardType.price < 0 ? undefined : cardType.price,\n                      rules: [\n                        { required: true, message: '请输入客户价格' }\n                      ]\n                    }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2}})],1)],1)],1)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }